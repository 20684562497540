import React from 'react';
import {Carousel} from 'react-bootstrap';
import Image from '../../components/imagesComponents/image'

const SliderBootstrapMovil = () => {


    return(
        <Carousel indicators={false} slide={false} controls={false} >
            <Carousel.Item >
                <img  style={{height:'300px'}}
                    className="d-block w-100"
                    src="../../../images/imageslider2.png"
                    alt=""
                    /> 
                    {/* <div style={{backgroundColor:'rgba(253, 254, 254,0.8)', width:'200px',height:'auto',position:'absolute',top:'30px'}}>
                        <Image/>
                    </div> */}
            
                        <div style={{
                            backgroundColor:'rgba(253, 254, 254,0.8)', 
                            width:'70%',marginLeft:'15%',
                            height:'auto',
                            position:'absolute',
                            top:'0%',
                            textAlign:'center'
                            }}>
                            <Image/>
                        </div>
          

                    {/* <Carousel.Caption style={{textAlign:'left',marginLeft:'-8%',background: 'rgba(255, 255, 255, 0.6)',padding:'3%',width:'90%'}}>
                       
                        <p style={{color:'#000',fontSize:'12px'}}>Let our proven experience upstage the competition and get you even more on the sale of your home!</p>
                    </Carousel.Caption> */}
                </Carousel.Item>
                {/* <Carousel.Item >

                <img style={{height:'300px'}}
                    className="d-block w-100"
                    src="../../../images/homeslide200small.jpg"
                    alt=""
                    /> 
  
                <Carousel.Caption style={{textAlign:'right',marginLeft:'-8%', color:'#000',background: 'rgba(255, 255, 255, 0.6)',padding:'3%', width:'90%'}}>
                    <h3 style={{color:'#000',fontSize:'14px'}}> NO guesswork &amp; NO wasted time!</h3>
                    <p style={{color:'#000',fontSize:'12px'}}>Upstaging your home for a better, quicker sale!</p>
                    </Carousel.Caption>
                </Carousel.Item> */}
        </Carousel>
    )
}

export default SliderBootstrapMovil;