import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
// import SliderComponent from "../components/slider/SliderComponent";
import {Container, Row, Col} from "react-bootstrap";
import SliderBootstrap from '../components/slider/SliderBootstrap';
import SliderBootstrapMovil from '../components/slider/SliderBootstrapMovil';
import indexStyle from '../styles/index.module.css'
// import CustomImage from "../components/imagesComponents/CustomImage";


const IndexPage = () => (
  <Layout >
    <SEO title="Home" />
    <body className={indexStyle.font}>
      {/* -- Sliders -- */}
      <Container className={indexStyle.desktop}>
        <Row>
          <SliderBootstrap/>
        </Row>
      </Container>
      <Container className={indexStyle.movil}>
        <Row>
          <SliderBootstrapMovil/>
        </Row>
      </Container>

      <Container className={indexStyle.containerOne}>
        <Row className={"justify-content-md-center"}>
          <Col sm={12} md={10}  >
            <p className={indexStyle.sloganOne}>
              Let our proven experience upstage the competition and get you even more on the sale of your home!
            </p>
          </Col>
        </Row>
      </Container>
      
      <Container className={indexStyle.containerOne}>
        <Row className={"justify-content-md-center"}>
          <Col sm={12} md={10} 
          //className={indexStyle.columLeft} 
          >
            <h3 className={indexStyle.title} style={{color:'#E9BF3C'}}>Utilize our services to enhance your home and upstage the market competition when you sell</h3> 
            <p style={{textAlign:'justify'}}>Our services are  designed to assess your needs—whether you are aging in place, dissolving an estate, moving, or selling a home. Our professional and thorough home preparation services are customized to fit your needs and budget with a commitment to making a smooth transition for you, and to help you achieve the maximum return on your home investment.</p>
            <p style={{textAlign:'justify',padding:0,marginBottom:0}}>If you are selling your home, our services are
            designed to help you achieve the maximum return on your investment.</p>
          </Col>
        </Row>
      </Container>

      <Container className={indexStyle.containerOne}>
        <Row className={"justify-content-md-center"}>
      
          <Col sm={12} md={2}>
          <div className={indexStyle.imageOneBox}>
            <div className={indexStyle.imageOne} style={{ 
                      backgroundImage:`url("../../images/about.png")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition:'center',
                      backgroundSize:"cover"
                      }}/>
                      {/* <br/><br/> */}
            </div>
          </Col>
          <Col sm={12} md={8}>
            <div style={{textAlign:'justify'}}>
              <h3 className={indexStyle.title} style={{color:'#E9BF3C'}}>The Simple Truth is ...</h3> 
              <p style={{textAlign:'justify',padding:0,marginBottom:0}}>
              Homes that are clean, well maintained, have pleasing décor, and curb appeal sell faster and for more money. 
              The knowledge, skill, and art of presenting your home in its best possible light comes from decades of real 
              estate sales experience with homes that need attention before marketing and keenly observing Buyer’s purchasing 
              desires.
              </p>
            </div>
          </Col>

        </Row>
      </Container>

      <Container className={indexStyle.containerOne}>
        <Row className={indexStyle.containerOneContext,"justify-content-md-center"} >
          <Col sm={12} md={10} >
              <h3 className={indexStyle.title} style={{color:'#E9BF3C'}}>Our team</h3> 
              <p style={{textAlign:'justify',padding:0,marginBottom:0}}>Includes professionals whose own life
              experiences have been touched
              by family transitions with loved
              ones. We provide guidance while
              serving our customers, honoring
              each situation respectfully.
              </p>
          </Col>
        </Row>
      </Container>

      <Container className={indexStyle.containerOne}>
        <Row >
            <Col sm={12} md={{span:10, offset:1}}>
              <div style={{textAlign:'left'}}>
                {/* <p className={indexStyle.boxLeft}> */}
                    With exception of the estate sale, most services can be paid at close of escrow when a successful sale is complete. With
                    a home inspection prior to sale, there should be no surprises during the transaction and your increased market equity
                    enables you to cover the preparation cost.
                {/* </p> */}
                <p className={indexStyle.sloganTwo} style={{color:'#E9BF3C',marginBottom:0}}>You pocket the profit!</p>
              </div>
            </Col>
        </Row>
      </Container>

      <Container className={indexStyle.containerOne}>
        <Row>
          <Col sm={12} md={{span:10, offset:1}}>
              <h3 className={indexStyle.title} style={{color:'#E9BF3C'}}><label style={{color:'gray'}}>No</label> guesswork & <label style={{color:'gray'}}>No</label> wasted time!</h3> 
              <div style={{textAlign:'left',padding:0,margin:0}}>
              Your never spend time and energy expended calling, scheduling, or waiting for contractors! Our team keeps you from low 
              offers and predatory investors and will identify and accomplish the key preparations to list your home for sale with 
              market appeal.
              <p className={indexStyle.sloganTwo} style={{color:'#E9BF3C',marginBottom:0}}>Upstaging your home for a better, quicker sale!</p>
              </div>
          </Col>
        </Row>
      </Container>

      {/* <Container className={indexStyle.containerSevenkeys}>
        <Row >
          <Col sm={12} md={1} />
          <Col sm={12} md={2} >
            <div className={indexStyle.imageKeysBox} >
              <div className={indexStyle.imageKeys} style={{
                backgroundImage:`url("../../images/sevenkeys.png")`,
                backgroundRepeat: 'no-repeat'
                }}/>
            </div>
          </Col>
          <Col sm={12} md={4} className={indexStyle.containerSevenkeysBoxItems} >
            <ul style={{listStyle:'none',padding:0,margin:0}}>
              <li className={indexStyle.itemListKeys}>
                <div className={indexStyle.listItemBox}>
                  <label className={indexStyle.listItemLabel}>1</label>
                  <p className={indexStyle.listItemP}>Household organization, decluttered, sale (garage or estate) or disposal, packing.</p>
                </div>
              </li>
              <li className={indexStyle.itemListKeys}><div className={indexStyle.listItemBox}><label className={indexStyle.listItemLabel}>2</label><p className={indexStyle.listItemP}>Professional home cleaning &amp; detailing.</p></div></li>
              <li className={indexStyle.itemListKeys}><div className={indexStyle.listItemBox}><label className={indexStyle.listItemLabel}>3</label><p className={indexStyle.listItemP}>Home Inspection to meet market standards.</p></div></li>
              <li className={indexStyle.itemListKeys}><div className={indexStyle.listItemBox}><label className={indexStyle.listItemLabel}>4</label><label style={{padding:0,margin:0}}>Boost curb appeal. Fresh paint and landscaping as needed.</label></div></li>
            </ul>
          </Col>
          <Col sm={12} md={4} className={indexStyle.containerSevenkeysBoxItems}>
            <ul style={{listStyle:'none',padding:0,margin:0}}>
              <li className={indexStyle.itemListKeys}><div className={indexStyle.listItemBox}><label className={indexStyle.listItemLabel}>5</label><p className={indexStyle.listItemP}>Deferred carpentry or remodel projects completed.</p></div></li>
              <li className={indexStyle.itemListKeys}><div className={indexStyle.listItemBox}><label className={indexStyle.listItemLabel}>6</label><p className={indexStyle.listItemP}>Staging to showcase the home’s features.</p></div></li>
              <li className={indexStyle.itemListKeys}><div className={indexStyle.listItemBox}><label className={indexStyle.listItemLabel}>7</label><label style={{padding:0,margin:0}}>List the home, Upstaging the competition.</label></div></li>
            </ul>
          </Col>
          <Col sm={12} md={1} />
        </Row>
      </Container> */}
      <Container className={indexStyle.containerSevenkeys}>
        <Row >
          <Col sm={12} md={1} />
          <Col sm={12} md={10} >
    
              <div className={indexStyle.imageKeys}  style={{
                
                backgroundRepeat: 'no-repeat',
                backgroundSize:'contain'
                }}/>

          </Col>
          </Row>
      </Container>
    </body>
  </Layout>
)

export default IndexPage;
