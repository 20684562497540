import React from 'react';
import {Carousel} from 'react-bootstrap';
import Image from '../../components/imagesComponents/image'

const SliderBootstrap = () => {


    return(
        <Carousel indicators={false} slide={false} controls={false} >
            <Carousel.Item >
                <img 
                    className="d-block w-100"
                    src="../../../images/imageslider2.png"
                    alt=""
                    />
                    {/* <div style={{
                        backgroundColor:'rgba(253, 254, 254,0.8)',
                        width:'500px',
                        height:'200px',
                        position:'absolute',
                        top:'100px'
                        }}>
                        <Image/>
                    </div> */}
                    <div style={{
                        backgroundColor:'rgba(253, 254, 254,0.8)',
                        width:'100%',
                        height:'150px',
                        position:'absolute',
                        top:'0px',alignContent:'center'

                        }}>
                            <center><div style={{width:'380px',height:'150px',display:'inline-block',alignSelf:'center'}}>
                            <Image/>
                            </div></center>
                    </div>
                <Carousel.Caption style={{
                    textAlign:'left',
                    marginLeft:'-5%',
                    //background: 'rgba(255, 255, 255, 0.6)',
                    padding:'2% 1% 1% 5%',
                    width:'80%'}}>
                    {/* <h3 style={{color:'#000',opcity:1}}>Let our proven experience </h3> */}
                    {/* <p style={{color:'#000',opcity:1}}>upstage the competition and get you even<br/>more on the sale of your home!</p> */}
                    {/* <p style={{color:'#000',opcity:1, fontSize:'30px'}}>Let our proven experience upstage the competition and get you even more on the sale of your home!</p> */}
                </Carousel.Caption>
            </Carousel.Item>
                {/* <Carousel.Item >

                <img 
                    className="d-block w-100" 
                    src="../../../images/homeslider200.png"
                    alt=""
                    />
                
                    <Carousel.Caption style={{textAlign:'right',marginLeft:'15%',marginRight:'0%',padding:'1% 4%', background: 'rgba(255, 255, 255, 0.6)',width:'60%' }}>
                        <h3 style={{color:'#000',opcity:1}}> NO guesswork &amp; NO wasted time!</h3>
                        <p style={{color:'#000',opcity:1}}>Upstaging your home for a better, quicker sale!</p>
                    </Carousel.Caption>
                </Carousel.Item> */}
        </Carousel>
    )
}

export default SliderBootstrap;